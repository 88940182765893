import styled from "styled-components";
import {FaTimes} from'react-icons/fa';
import {Link as LinkS} from'react-scroll';
import {Link as LinkR} from 'react-router-dom'
import { HashLink as HashLinks } from 'react-router-hash-link';

export const SidebarContainer =styled.aside`
position:fixed;
z-index:999;
width:100%;
height:100%;
background:#000;
display:grid;
align-items:center;
top:0;
left:0;
transition:0.3 ease-in-out;
opacity:${({ isOpen })=>(isOpen ? '100%':'0' )};
top:${({isOpen})=>(isOpen? '0':'-100%')};

`
;


export const CloseIcon=styled(FaTimes)`
color:#ffde59;
`;

export const Icon=styled.div`
position:absolute;
top:1.2rem;
right:1.5rem;
background:transparent;
font-size:2rem;
cursor:pointer;
outline:none;`;


export const SidebarWrapper=styled.div`
color:#fff;`;

export const SidebarMenu=styled.ul`
display:grid;
grid-template-columns:1fr;
grid-template-rows:repeat(6,80px);
text-align:center;

@media screen and (max-width:480px){
    grid-template-rows:repeat(6,60px);
}

`;
export const SidebarLink=styled(LinkS)`
display:flex;
align-items:center;
justify-content:center;
font-size:1.5rem;
text-decoration:none;
transition:0.2s ease-in-out;
cursor:pointer;

&:hover{
    color:#ffde59;
    transition:0.2s ease-in-out;
}
`;


export const SideBtnWrap=styled.div`
display:flex;
justify-content:center;
`;


export const SidebarRoute=styled(LinkS)`
border-radius:50px;
background:#ffde59;
white-space:nowrap;
padding:16px 64px;
color:#000;
font-size:16px;
outline:none;
border:none;
text-decoration:none;
cursor:pointer;
transition:all 0.2s ease-in-out;

&:hover{
    transition:all 0.2s ease-in-out;
    background:#ffde59;
}
`;

export const NavLinkr=styled(LinkR)`
display:flex;
align-items:center;
justify-content:center;
font-size:1.5rem;
color:#fff;
text-decoration:none;
transition:0.2s ease-in-out;
cursor:pointer;

&:hover{
    color:#ffde59;
    transition:0.2s ease-in-out;
}
`;




export const HashLink= styled(HashLinks)`
display:flex;
align-items:center;
justify-content:center;
font-size:1.5rem;
text-decoration:none;
transition:0.2s ease-in-out;
cursor:pointer;

&:hover{
    color:#ffde59;
    transition:0.2s ease-in-out;
}
`;
