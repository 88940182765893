import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '../components/Typography';
 
export default function Copyright() {
  return (
    <div id='copyright'>
 
    <Box  
    component="section"   
    sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#000',flexDirection: 'column', margin:'0', padding:'10px' }}
  >  
   

    <Typography  style={{margin:'0', padding:'40px'}} align="center" component="p" data-aos='fade-right'
          data-aos-duration='1000'
          data-aos-once='true'
          data-aos-anchor-placement='center bottom'>
    &copy; Centre Point, Hong Kong, 2024
    </Typography>
     
  
  
  </Box></div>
  );
}
