import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import api from '../components/axios';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Tripadvisor from '../components/Tripadvisor';
import OpenRice from '../components/OpenRice';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function SocialMedia() {
  const endpoint="social/"
  const [socials, setSocials]=useState([])

function renderSwitch(params) {
    switch(params){
      case'twitter':
        return <TwitterIcon style={{height:'50px', width:'50px'}}/>;
      case'facebook':
        return <FacebookIcon style={{height:'50px', width:'50px'}}/>;
      case'googlemap':
        return <LocationOnIcon style={{height:'50px', width:'50px'}}/>;
      case'instagram':
        return <InstagramIcon style={{height:'50px', width:'50px'}}/>;
      case'tripadvisor':
        return <Tripadvisor style={{height:'50px', width:'50px'}}/>;
      case'whatsapp':
        return <WhatsAppIcon style={{height:'50px', width:'50px'}}/>;
      case'openrice':
        return <OpenRice style={{height:'50px', width:'50px'}}/>;
      default:
        return <LocationOnIcon style={{height:'50px', width:'50px'}}/>;
    }
  }
  
  useEffect(() => {
    api.get(endpoint).then(res=>{
      setSocials(res.data)
    })
  },[])
  return (
    <Box  
    component="section"   
    sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#000',flexDirection: 'column', margin:'0', paddingTop:'40px' }}
  >  
   

    <Grid item md={12} style={{ padding:'0'}}  align="center"> 
    {socials.map((social, index)=>(
        <a rel="noopener noreferrer"  key={index} style={{color:"#ffde59", padding:'1rem'}} href={social.link} target="_blank" >
        
        {renderSwitch(social.name)}
        
        </a>
    ))}
    </Grid>

   </Box>
  )
}

export default SocialMedia

