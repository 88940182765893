import React, { useState, useEffect } from "react";
import {
  Content,
  Section,
  Item,
  Image,
  ItemTitle,
  ItemDescription,
  ItemLink,
} from "./Styledcs";
import api from "../components/axios";

// const baseURL=baseURL
const endpoint = "events/";

export default function Events() {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [events, setEvents] = useState([]);
  useEffect(() => {
    api.get(endpoint).then((res) => {
      // console.log(res.data)
      // Result Array of Python Dictionary items[{…}, {…}, {…}]
      setEvents(res.data);
    });
  }, []);

  return (
    <div
      id="events"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-once="true"
      data-aos-anchor-placement="center bottom"
    >
      <Section>
        <Content style={{ margin: "80px 0 0 0" }}>
          {events.map((event, index) => (
            <Item
              key={index}
              data-aos="zoom-out-up"
              data-aos-duration="1200"
              data-aos-once="true"
              data-aos-anchor-placement="center bottom"
            >
              <ItemLink to={`/events/${event.name}`}>
                <Image src={event.image} alt={event.name} />
              </ItemLink>
            </Item>
          ))}
        </Content>
      </Section>
    </div>
  );
}
