import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Section,
  Content,
  Leftsection,
  LeftsectionDestails,
  Rightsection,
  ItemLink,
  LargeImage,
  Item,
  Wrapper,
} from "./Styledcs";
import Typography from "../components/Typography";
import api from "../components/axios";
import styled from "styled-components";
import { Key } from "@mui/icons-material";

export const FoodBtnLinkr = styled(Link)`
  border-radius: 10px;
  background: #ffde59;
  white-space: nowrap;
  padding: 5px 52px;
  min-width:200px;
  margin: 10px;
  color: #000;
  text-transform:uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  float: right;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &a {
    color: #fff;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    background: black;
    color: #fff;
  }
`;

const FoodDriks = () => {
  const endpoint = "fooddrink/";
  const initialData={
    id: null,
    name: "",
    image:null,
    description: "",
    published: false
  }

  const [fooddrinksDetails, setFooddrinksDetails] = useState(initialData);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    api.get(endpoint).then((res) => {
      const datarcd=res.data
      const fooddrinksdata = datarcd[0];
      const myimage=JSON.parse(fooddrinksdata['image']);
      fooddrinksdata['image']=myimage
      const menusdata = datarcd.filter((e,i)=>i!==0);
      setFooddrinksDetails(fooddrinksdata);
      setMenus(menusdata);
      // console.log("My Image:"+JSON.stringify(fooddrinksdata['name']));
      // console.log("MenuListdata:"+JSON.stringify(menusdata));

    });
  }, []);

  return (
    <div id="food&drinks">
      <Section style={{ margin: "0px 0", padding:"0", height:"100%" }}>
        <Content style={{ margin: "0px 0", padding:"0" }}>
            <Wrapper style={{ backgroundColor: "rgb(233 112 2)" }}>
              <Leftsection style={{ height:"100%", margin:"0", padding:"0" }}>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                  data-aos-once="true"
                  data-aos-anchor-placement="center bottom"
                >
                  <Typography
                  
                    variant="h1"
                    marked="center"
                    align="left"
                    component="h1"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-once="true"
                    data-aos-anchor-placement="center bottom"
                  >
                    {fooddrinksDetails.name}
                  </Typography>

                </div>

                <div
                  style={{
                    display: "flex",
                    frexWrap: "wrap",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <br/>

{menus.map((item, index) => (
<>
                  <FoodBtnLinkr key={index}
                     
                    to={`/fooddrinks/${item.name}`}
                  >
                    <h4> {item.name}</h4>
                  </FoodBtnLinkr>
                  </>))}
                </div>
              </Leftsection>
              <Rightsection style={{height:"83vh"}} >
                <LargeImage
                  src={fooddrinksDetails.image}
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  data-aos-once="true"
                  data-aos-anchor-placement="center bottom" 
                  style={{height:"100%"}}
                />
              </Rightsection>
            </Wrapper>
          
        </Content>
      </Section>
    </div>
  );
};

export default FoodDriks;
