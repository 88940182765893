import React, {  useEffect } from 'react';
 
import AppFooter from '../views/AppFooter'
import AppBar from '../components/AppBar';
import withRoot from '../withRoot';
 
 
import Aos from 'aos';
import 'aos/dist/aos.css';




function Index() {


  useEffect(() => {
    window.scrollTo(0, 0)
    Aos.init({});
  }, []);

  
  return (
    <React.Fragment>
      <AppBar/>
       
      <AppFooter/>
    </React.Fragment>
  );
}

export default withRoot(Index);
