import styled from 'styled-components';
import {Link as LinkR } from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
import { HashLink as HashLinks } from 'react-router-hash-link';

export const Nav=styled.nav`
height:80px;
color:#fff;
margin-top:-80px;
display:flex;
justify-content:center;
align-items:center;
font-size:1rem;
position:sticky;
top:0;
z-index:100;

@media screen and (max-width:960px){
    transition:0.8s all ease;
}
`;


export const BackBtnLink=styled(LinkS)`
border-radius:30px;
background:#ffff00;
padding:10px 22px;
color:#000;
font-size:16px;
border:none;
cursor:pointer;
transition:all 0.2s ease-in-out;
text-decoration:none;

&:hover{
    transition:all 0.2s ease-in-out;
    background:#fff;
    color:#df1628;
}`;


export const NavbarContainer=styled.div`
display:flex;
justify-content:space-between;
height:80px;
z-index:1;
width:100%;
max-width:1100px;
`;

export const NavLogo=styled(LinkR)`
color:#fff;
justify-self:flex-start;
cursor:pointer;
font-size:1.5rem;
display:flex;
align-items:center;
margin-left:24px;
font-weight:bold;
text-decoration:none;
`;

export const MobileIcon=styled.div`
display:none;

@media screen and (max-width:768px){
    display:block;
    position:absolute;
    top:0;
    right:0;
    transform:translate(-100%, 60%);
    font-size:1.8rem;
    cursor:pointer;
    color:#fff;

}
`;


export const NavMenu =styled.ul`
display:flex;
align-items:center;
list-style:none;
text-align:center;
margin-right:-22px;

@media screen and (max-width:768px){
    display:none;
}
`;
export const NavItem = styled.li`
height:80px;
`;

export const NavLinks=styled(LinkS)`
color:'#ebe0bd';
display:flex;
align-items:center;
text-decoration:none;
cursor:pointer;
padding:0 1rem;
height:100%;


&.active{
    border-bottom:3px solid #ffde59;
    padding-top:3px;
}

`;

export const HashLink= styled(HashLinks)`
color:#ffde59;
display:flex;
align-items:center;
text-decoration:none;
cursor:pointer;
padding:0 1rem;
height:100%;

&.active{
    border-bottom:3px solid #ffde59;
    padding-top:3px;
}

`;

export const HashLinkGallery=styled(HashLinks)`
color:#ffff00;
display:flex;
align-items:center;
text-decoration:none;
padding:0 1rem;
height:168%;
border-bottom:3px solid #ffff00;
padding-top:3px;
`



export const NavLinkr=styled(LinkR)`
color:#ebe0bd;
display:flex;
align-items:center;
text-decoration:none;
cursor:pointer;
padding:0 1rem;
height:100%;

&.active{
    border-bottom:3px solid #91C992;
}

`;

export const NavBtn =styled.nav`
display:flex;
align-items:center;

@media screen and (max-width:768px){
    display:none;
}`;

export const BackBtn =styled.nav`
display:flex;
align-items:center;
`;

export const NavBtnLink=styled(LinkS)`
border-radius:50px;
background:#ffde59;
white-space:nowrap;
padding:10px 22px;
color:#000;
font-size:16px;
outline:none;
border:none;
cursor:pointer;
transition:all 0.2s ease-in-out;
text-decoration:none;

&:hover{
    transition:all 0.2s ease-in-out;
    background:#fff;
    color:#000;
}
`;





export const FoodBtn =styled.nav`
display:inline;
align-items:center;
`;


export const FoodBtnLinkr=styled(LinkR)`
border-radius:15px;
background:#ffff00;
white-space:nowrap;
padding:10px 52px;
margin:10px;
color:#010606;
font-size:16px;
outline:none;
border:none;
cursor:pointer;
float:right;
transition:all 0.2s ease-in-out;
text-decoration:none;

&:hover{
    transition:all 0.2s ease-in-out;
    background:#fff;
    color:#000;
}
`;
