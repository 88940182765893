import React, {useState, useEffect} from 'react';
import {Section, Content,Leftsection,LeftsectionDestails, Rightsection, LargeImage, Item, Wrapper} from './Styledcs'
import Typography from '../components/Typography';
import api from '../components/axios'

const Aboutus = () => {
  const endpoint="shop/"

  const [aboutus, setAboutus]=useState([])

  useEffect(()=>{
    api.get(endpoint).then(res=>{
      setAboutus(res.data) 

    })
  },[])
  return (
    <div id="liveband">
      <Section style={{ margin: "0px 0", padding:"0", height:"100%" }}>
        <Content style={{ margin: "0px 0", padding:"0" }}>
               
     
      {aboutus.map((item,index)=> 

    (
      <Wrapper style={{ backgroundColor: "rgb(233 112 2)" }} key={index}>
          <Leftsection style={{ height:"100%", margin:"0", padding:"0" }}>
          
            <div
              data-aos='fade-right'
              data-aos-duration='1200'
              data-aos-delay='300'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            >
              <Typography  variant="h1" marked="center" align="left" component="h1" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
      {item.name}
      </Typography>
              
              
                
               

                
                 
                    <p className='description'>
                 <LeftsectionDestails dangerouslySetInnerHTML={{__html: item.description}}  ></LeftsectionDestails> </p>
            </div>
          </Leftsection>
          <Rightsection>
            <LargeImage
              src={item.image}
              data-aos='fade-left'
              data-aos-duration='1200'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            />
          </Rightsection>
        </Wrapper>  
      ))}
     </Content>
     </Section>
   </div>
  );
};

export default Aboutus;
