import React, { useContext, useEffect, useState } from 'react';
import {FaBars} from 'react-icons/fa'
import {animateScroll as scroll } from "react-scroll";
import {Nav,NavLogo,NavbarContainer, MobileIcon, NavMenu, NavItem,NavLinks, NavBtn,NavBtnLink,HashLink} from './NavbarElements.js';
import api from '../axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import NavigationContext from '../../context/NavigationContext.js';
import { useLocation } from 'react-router-dom';
import logo from '../../images/centrepointlogo.png'
 
const Navbar = ({toggle}) => {
    const [loading,setLoading]=useState(true)
    const endpoint="navigation/"
    const [navbar, setNavbar]=useContext(NavigationContext);
    const [navbarData, setNavbarData]=useState([])
    const location = useLocation();
    // console.log(location)
    const isHome=location.pathname==="/"?true:false;
    // console.log("isHome:"+isHome)


    useEffect(()=>{
        api.get(endpoint).then(res=>{
            setNavbarData(res.data)
            setLoading(false)
        })
    },[])


    return (
        <Nav style={{backgroundColor:navbar? '#000':'',}}>
            <NavbarContainer>
                <NavLogo to ="/" onClick={() => {
    scroll.scrollToTop();
}}>
    <img src={logo} alt="Logo" width={300} />
    </NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>

{loading?
( <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      )
      :(

         <NavMenu>
                    
                        {navbarData.map((item, index)=>( 
                            
                            <NavItem key={index}>

                                {isHome?(
                                <NavLinks 
                                style={{color:'#fff', fontWeight: "bold", textTransform: "uppercase"}}
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                to={item.link} >{item.name}</NavLinks>)
                                :
                                (      
                                <HashLink 
                                style={{color:'#fff', fontWeight: "bold", textTransform: "uppercase"}}
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                to={"/#"+item.link}>{item.name}</HashLink>
                                )}
                             
                             </NavItem>
                        ))}
                    
                </NavMenu>)}

                <NavBtn>
                    
                <NavBtnLink         
                style={{textTransform: "uppercase"}}
 to ='contactus' onClick={() => scroll.scrollToBottom()}>Contact Us</NavBtnLink>  
            
            
                </NavBtn>
            </NavbarContainer>
            
        </Nav>
    )
}

export default Navbar
