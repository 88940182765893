import styled from 'styled-components';
import {Link} from 'react-router-dom';


export const Section=styled.div`
overflow:hidden;
background-color:#eaeaf4;
min-height:83vh ;
`

export const Content=styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
    justify-content: center;
`
export const Item=styled.div` 
    margin:20px;
    min-height:400px;
    max-width:300px;
    object-fit: cover;
    `
export const LargeImage=styled.img` 
    width: 100%;
    height:100%;
    object-fit: cover;
`


  export const Image=styled.img` 
    width: 100%;
    height:400px;
    object-fit: cover;
    
`

export const ItemTitle=styled.h3`
text-align: center;
  text-transform:uppercase;
  font-size: 110%;
  margin: 5px;
`


export const ItemDescription=styled.p`
 text-align: center;
  font-size: 90%;
  padding:0 20px;
`


export const ItemLink=styled(Link)`
    display: block;
    font-size: 80%;
    padding:0px;
    margin:0px;
`


  /* #ABOUT US SECTION */
  
  export const Wrapper=styled.div` 
    display: flex;
    flex-direction: row;
    background-color: #fff;
    width:100% ;
    align-items: top;
    justify-content: center;
    min-height:80vh;
    margin:0;

    @media screen and (max-width: 800px) {
    flex-direction: column;
      flex-direction: column-reverse;
      margin: 0;
      padding: 0;};
`

export const Leftsection=styled.div` 
 background-color: #fff;
    width: 50%;
    max-width: 800px;
    margin-top: 80px;
    padding:0;

    h1 {
      margin-top:80px ;
      text-align:center;
    }
    
    
    @media screen and (max-width: 800px) {
        width: 100%;
        align-items: flex-end;
        margin-top: 0px;
        padding: 0em;


        h1 {
      margin-top:0px ;
      text-align:center;
    }
       };
`

export const LeftsectionDestails=styled.p` 
text-align:justify;
  font-size: 100%;
  padding:0;
`

export const Rightsection=styled.div` 
    background-color: #fff;
    width: 50%;
    max-width: 800px;

    h1 {
      margin-top:80px ;
      text-align:center;
    }

    @media screen and (max-width: 800px) {
        width: 100%;


        h1 {
      margin-top:0px ;
      text-align:center;
    }
       };
`
 
 
  

  
  export const ContactusButton=styled.div`
      margin: 10px auto;
      background-color: #fbf3da;
      color:#000;
      display:flex;
      width:100%;
      padding:10px;
`