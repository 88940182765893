import React, {useState} from 'react';
import './App.css'
import {
  // BrowserRouter,
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";

 
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import NavigationContext from './context/NavigationContext';
import FoodDrinksPage from './pages/FoodDrinksPage';
import PageNotFound from './pages/PageNotFound';

 
 


function App() {
  
  const[navbar,setNavbar]=useState()
  const changeBackground=()=>{
    if(window.scrollY>=90){
        setNavbar(true);
    }else{
        setNavbar(false);
    }
};

window.addEventListener("scroll",changeBackground);

  return (
    <NavigationContext.Provider value={[navbar,setNavbar]}>

  <Router>
    <Routes>
      <Route path="/" exact element={<HomePage />} />
      <Route path="/events/:eventId" element={<EventsPage />} />
      <Route path="/fooddrinks/:eventId" element={<FoodDrinksPage />} />
      <Route path="*" element={<PageNotFound />} />
      
      
    </Routes>
  </Router>
  </NavigationContext.Provider>
)};

export default App;
