import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../components/Typography';
import {Link as LinkR } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import api from '../components/axios';
import CloseIcon from '@mui/icons-material/Close';
import { HashLink } from 'react-router-hash-link';
 

const Section = styled.section`
  /* background-color: #f7f7f9; */
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;
  justify-content: center;
  margin:0em;
  padding:4rem;

  @media screen and (max-width: 768px) {
    padding:0rem ;
    margin:0px;
    display: flex;
    flex-wrap:wrap;
    flex-direction: column;
    justify-content: center;
    
  }
`;

 

const ColumnLeftside = styled.div`
  margin: 0px;
  padding: 0px;
  /* max-width: 850px; */
  width: 50%;
  min-height: 60vh;
  background-color:"#000" ;


 
  @media screen and (max-width: 768px) {
    min-height: 420px;
    width: 100%;
    margin: 0px;
    padding:0px;
  }

`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  margin:4em;
  flex: 0 0 50%;
  color: #000;
  width:100%;
  display:block;
  padding:30px;
  margin:150px 0 0 0;
  background-color:#fff;
  border:1px solid #df1628;
  border-left-style: none;
  

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom:5em;
    border:1px solid #df1628;
    margin:0;

    
  }

  h1 {
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 3rem;
   
  }

  h2 {
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 2rem;
  }

  h4 {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    font-size: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
`;

const ColumnRightside = styled.div`
  /* background-color: #fff; */
  max-width: 800px;
  min-height: 60vh;
  width: 40%;
  padding: 0rem;
  margin:0 ;
  justify-content: center;


  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    left: 0px;
    justify-content: center;
    
  }

`;



export const FoodBtn =styled.nav`
display:inline-center;
align-items:center;
font-size:70%;
`;


export const FoodBtnLinkr=styled(LinkR)`
border-radius:15px;
background:#ebe0bd;
white-space:nowrap;
padding:10px 52px;
margin:10px;
color:#010606;
font-size:8px;
outline:none;
border:none;
cursor:pointer;
float:right;
transition:all 0.2s ease-in-out;
text-decoration:none;

&:hover{
    transition:all 0.2s ease-in-out;
    background:#fbe0bd;
    color:#000;
}
`;

const endpoint="events/"


const EventsDetails = () => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const params = useParams();
  const enquiredevent=params.eventId
  // console.log(enquiredevent);

  const [ events, setEvents ] = useState([]);
  useEffect(()=> {
        // call Data from api
        api.get(endpoint).then(res=>{
            setEvents(res.data) 
        })
   

    }, 
    []); 

// Finding index of object in array 
// where objects name property is equal to the value of "enquiredevent". "enquiredevent" value is passed from link 
  const myIndex=events.findIndex(e => e.name === enquiredevent)
// console.log(myIndex +"is my current index")

const getLength=events.length-1

// #Using filter and map for filtering and displaying data

// FILTER
// events.filter((e,i)=>i===(myIndex+1)) # (e,i) e is object and i is index.
// this will return object whose index is exactly equal to (myIndex+1)


// MAP
// events.map((e,i)=>i===(myIndex+1)) # (e,i) e is object and i is index.
// this will return object whose index is exactly equal to (myIndex+1)

//Get Next Event
const getNextEvent=events.filter((e,i)=>i===(myIndex+1)).map(d=>d.name)[0]
// const getNextEventis=events.filter((e,i)=>i===(myIndex+1)).map((d,index)=>d.name)
//Get Previous Event
const getPreviousEvent=events.filter((e,i)=>i===(myIndex-1)).map(d=>d.name)[0]



  return (
    <div style={{backgroundColor: "#f7f7f9", marginTop:"0px"}} >
     

        {events.map((event, index)=>(
          <div key={index}> { myIndex === index && (

          
            
    <Section id="eventsdetail" >
      <p id="navigationdetails">
      Events / {event.name}</p> 
     

        <ColumnLeftside id="columnleft">
            <Image
              src={event.image}
              data-aos='fade-left'
              data-aos-duration='1200'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            />
          </ColumnLeftside>


          <ColumnRightside id="columnright">

        
            <Content style={{}}
              data-aos='fade-right'
              data-aos-duration='1200'
              data-aos-delay='300'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            >
              <Typography style={{color:'#000', fontSize:"130%"}} variant="h4" marked="center" align="left" component="h5" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
      {event.name}
      </Typography>
      <Typography style={{color:'#000', fontSize:"130%"}} align="left" component="h5" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
      {event.days}
      </Typography>
      <Typography style={{color:'#000', fontSize:"130%"}} align="left" component="h5" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
      {event.eventtime}
      </Typography>


       


              <p>{event.description}</p>
               
               
<div style={{display:"flex", frexWrap:"wrap", flexDirection:"column", alignItems:"center"}}>
{/* <FoodBtn key={index}>
                    
                    <FoodBtnLinkr to ={"#"}><h4>hi{event.button.toUpperCase()}</h4></FoodBtnLinkr>
                
                
                    </FoodBtn> */}

  </div>


            </Content>
          </ColumnRightside>

          {myIndex !== 0 && (
         <Link to={`/events/${getPreviousEvent}`}> 
      <ArrowBackIosIcon style={{position:"absolute",
        top: "60%",
        left:"20px", color:"#df1628", fontSize:"250%", zIndex:"1000" }}/>
        </Link>
      )}
      
          {myIndex !== getLength && (
           
           <Link to={`/events/${getNextEvent}`}>
         <ArrowForwardIosIcon  style={{position:" absolute",
        top: "60%",
        right:"10px", color:"#df1628", fontSize:"250%" }}/>
        </Link>
        )}
         
    </Section>)}
    </div>
    
    ))}
    </div>
  );
};

export default EventsDetails;
