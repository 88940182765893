import React, {  useContext, useEffect } from 'react';
import AppFooter from '../views/AppFooter'

import withRoot from '../withRoot';
import EventsDetails from '../views/EventsDetails';

import Aos from 'aos';
import 'aos/dist/aos.css';
import AppBar from '../components/AppBar';

import { useLocation } from "react-router-dom";

import NavigationContext from '../context/NavigationContext';




function EventsPage() {
  const { pathname } = useLocation();
  const [navbar, setNavbar]=useContext(NavigationContext)
  setNavbar(true);


  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({});
  }, []);

  
  return (
    <React.Fragment>
      <AppBar />
      <EventsDetails />
      <AppFooter/>
    </React.Fragment>
  );
}

export default withRoot(EventsPage);
