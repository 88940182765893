import React, {  useEffect, useContext } from 'react';
 
import AppFooter from '../views/AppFooter'
import AppBar from '../components/AppBar';
import withRoot from '../withRoot';
import FoodDrinksDetails from '../views/FoodDrinksDetails';
import NavigationContext from '../context/NavigationContext';

import Aos from 'aos';
import 'aos/dist/aos.css';

import { useLocation } from "react-router-dom";


function ServicesPage() {
  const { pathname } = useLocation();
  const [navbar, setNavbar]=useContext(NavigationContext)
  setNavbar(true);



  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({});
  }, [pathname,]);

  
  return (
    <React.Fragment>
      <AppBar/>
      <FoodDrinksDetails/>
      <AppFooter/>
    </React.Fragment>
  );
}

export default withRoot(ServicesPage);
