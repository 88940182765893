import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Typography from "../components/Typography";
import api from "../components/axios";
import {
  Section,
  Content,
  Leftsection,
  Image,
  LeftsectionDestails,
  Rightsection,
  LargeImage,
  Item,
  Wrapper,
} from "./Styledcs";

const LiveSports = () => {
  const initialData={
    id: null,
    name: "",
    image:null,
    description: "",
    published: false
  }
  const endpoint = "livesports/";
  const [livesports, setLivesports] = useState(initialData);
  const [livesportsimages, setLivesportsimages] = useState([]);
  useEffect(() => {
    api.get(endpoint).then((res) => {
      const datarcd=res.data
      const livesportsdata = datarcd[0];
      const myimage=JSON.parse(livesportsdata['image']);
      livesportsdata['image']=myimage
      // const livesportsimagedata = datarcd.filter((e,i)=>i===1);
      // const livesportsimage=JSON.parse(livesportsimagedata[0]['image']);
      // livesportsimagedata['image']=livesportsimage
      // console.log("Livesports image:"+JSON.stringify(livesportsdata))
      setLivesports(livesportsdata);
      // setLivesportsimages(livesportsimagedata);

    });
     
  }, []);

  return (
    <div id="livesports">
      
       
        <Section style={{ margin: "0", padding:"0", }}>
          <Content style={{ margin: "60px", padding:"0" }}>
            <Leftsection style={{ margin: "0", padding:"0"}}>
            {livesports.image && livesports.image.map((im,i)=>(
              i=== 0 ?(
                <Image  key={i}  style={{ width:"50%", height:"70vh", float:"left", border:"5px solid #fff"}}
                src={im}
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-once="true"
                data-aos-anchor-placement="center bottom"
              />
              ):
              (<Image  style={{ width:"50%", height:"35vh", float:"right", border:"5px solid #fff"}}
              src={im}
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-once="true"
              data-aos-anchor-placement="center bottom"
            />)
              
               
             ) )
        }
               
            </Leftsection>

            <Rightsection id="columnright">
              <Content
                
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="300"
                data-aos-once="true"
                data-aos-anchor-placement="center bottom"
              >
                 
                <Typography
            
                  variant="h1"
                  marked="center"
                  align="center"
                  component="h1"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  data-aos-anchor-placement="center bottom"
                >
                  {livesports.name}
                </Typography>
                <Typography
                  style={{ color: "#000", margin:"30px 60px" }}
                  variant="h5"
                  marked="center"
                  align="center"
                  component="h5"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  data-aos-anchor-placement="center bottom"
                >
                  {livesports.description}
                </Typography>
               
   
              </Content>
            </Rightsection>
          </Content>
        </Section>
    
    </div>
  );
};

export default LiveSports;
