import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import styled from "styled-components";
import api from "../components/axios";

const Contactus=styled.div` 
     display: flex;
      flex-direction: row;
      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
   `

const Divbox = styled.div`
  flex: 1;
  height: 300px;
  display: inline-block;
  overflow: hidden;
`;

export default function ContactUs() {
  const endpoint = "shop/";
  const [shopdetails, setShopdetails] = useState([]);
  const [mycolor, setMycolor]=useState("#eaeaf4");
  useEffect(() => {
    api.get(endpoint).then((res) => {
      setShopdetails(res.data);
    });
  }, []);

  // console.log(shopdetails)

  return (
    <div id="contactus">
      {shopdetails.map((data, key) => (
        <Contactus key={key} style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 1)",
         

        }}>
           <Divbox  
            className="openinghour"
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-anchor-placement="center bottom"
          >
            <Container
              style={{
                margin: "3em",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "inline-block",
                width: "70%",
              }}
            >
              <Typography
                variant="h4"
                marked="left"
                style={{
          
                  marginTop: "20px",
                  fontSize: "130%",
                }}
                align="center"
                component="h3"
              >
                OPENING HOURS
              </Typography>

              <p align="center" style={{ lineHeight: "2.5" }}>
                <AccessTimeIcon style={{ paddingTop: "8px" }} />{" "}
                {data.opendays}
              </p>
              <p align="center" style={{ lineHeight: "2.5" }}>
                <AccessTimeIcon style={{ paddingTop: "8px" }} />{" "}
                {data.open_close_time}{" "}
              </p>
 
            </Container>
          </Divbox>


          <Divbox
            className="openinghour"
            container
            style={{
              display: "flex",
               
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-anchor-placement="center bottom"
          >
            <Container
              style={{
                margin: "3em",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "inline-block",
                width: "70%",
              }}
            >
              <Typography
                variant="h4"
                marked="left"
                style={{
                  
                  marginTop: "20px",
                  fontSize: "130%",
                }}
                align="center"
                component="h3"
              >
                CONTACT US
              </Typography>

               
              <a
                style={{  lineHeight: "2.5" }}
                href={`tel:${data.phone}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <p align="center">
                  <CallIcon style={{ paddingTop: "8px" }} /> {data.phone}
                </p>
              </a>
              <a
                style={{ lineHeight: "2.5" }}
                href={`mailto:${data.email}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <p align="center">
                  <EmailIcon style={{ paddingTop: "8px" }} /> {data.email}
                </p>
              </a>

               
            </Container>
          </Divbox>

         

          <Divbox
            className="openinghour"
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-anchor-placement="center bottom"
          >
            <Container
              style={{
                margin: "3em",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "inline-block",
                width: "70%",
              }}
            >
              <Typography
                variant="h4"
                marked="left"
                style={{
                  marginTop: "20px",
                  fontSize: "130%",
                }}
                align="center"
                component="h3"
              >
                ADDRESS
              </Typography>

               

              
              <h4
                style={{ marginBottom: "0px", lineHeight: "2.5" }}
                align="center"
              >
                <LocationOnIcon style={{ paddingTop: "8px" }} /> Address
              </h4>
              <div
                style={{ paddingLeft: "30px", lineHeight: "2.5" }}
                align="center"  
                dangerouslySetInnerHTML={{__html: data.address}}
              >
                
      
              </div>
            </Container>
          </Divbox>
        </Contactus>
      ))}
    </div>
  );
}
