import * as React from 'react';

import SocialMedia from'./SocialMedia'
import ContactUs from'./ContactUs'
import Copyright from'./Copyright'

export default function AppFooter() {
  return (
     <>
    <SocialMedia/> 
    <ContactUs/>
    <Copyright/> 
    </>
    
  );
}
