import React, { useEffect, useState } from 'react'
import { SidebarContainer, Icon,NavLinkr, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap,SidebarRoute,HashLink } from './SidebarElements'
import {animateScroll as scroll } from "react-scroll";
import { useLocation } from 'react-router-dom';
import api from '../axios';
import { Link } from "react-router-dom";

 
        
const Sidebar = ({toggle, isOpen}) => {
    const endpoint="navigation/"
    const location = useLocation();
    const isHome=location.pathname==="/"?true:false;


    const [navbarData,setNavbarData]=useState([])
    useEffect(()=>{
        api.get(endpoint).then(res=>{
            setNavbarData(res.data)
        })
    },[])
    return (
        <SidebarContainer isOpen={isOpen} toggle={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {navbarData.map((item,index)=>(
                        <>
                        {isHome?(
                       <SidebarLink to={item.link} key={index} onClick={toggle}>{item.name}</SidebarLink>)
                       :(<HashLink 
                        style={{color:'#fff', fontWeight: "bold", textTransform: "uppercase"}}
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to={"/#"+item.link}>{item.name}</HashLink>)}
                        
                        </>
                    ))}
                </SidebarMenu>
<SideBtnWrap>
                <SidebarRoute to="contactus" onClick={toggle}> 
Contact Us
                </SidebarRoute> 
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
            
        
    )
}

export default Sidebar
